import React from "react"

function LottieAnimation({ animation, width = "300px", height = "210px" }) {
  if( typeof document !== 'undefined'){

    const { Lottie } = require("react-lottie")
    return !!Lottie && (
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        width={width}
        height={height}
      />
    )
  }else{
    return null;
  }
}

export default LottieAnimation
